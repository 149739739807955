@import "../../../../../../lib/uio1/profile/css/util.less";

/*------------------------------------*\
# fonts
\*------------------------------------*/
// Font-license: https://scripts.sil.org/cms/scripts/page.php?site_id=nrsi&id=OFL
// https://www.fontsquirrel.com/fonts/concert-one
@font-face {
  font-family: "Concert One";
  font-style: normal;
  font-weight: 400;
  src: url("../fonts/concert-one.woff2") format("woff2");
}

@font-primary: "Roboto", sans-serif;
@font-secondary: "Roboto", sans-serif;
@font-symbol: "Concert One", cursive;

#hidnav a[href="#bottomnav"] {
  display: none;
}
:focus {
  outline: -webkit-focus-ring-color auto 5px;
}

html,
body {
  font-family: @font-secondary, Arial, sans-serif;
  line-height: 2.6rem;
  color: @color-dark;
}

a {
  font-family: @font-secondary;
  //  color: @color-link;
  font-weight: 500;
}

a.vrtx-more::before,
div.vrtx-more a::before,
a.all-messages::before,
a.all-comments::before,
.footer > .vrtx-search-scope a::before,
a.more-url::before {
  font-family: @font-primary;
  .font-size(20);
}

.introduction-div,
.vrtx-introduction-big,
.vrtx-frontpage-box.introduction .vrtx-box-content,
.vrtx-organizational-unit-introduction,
.vrtx-introduction {
  font-family: @font-primary;
  .font-size(20);
  line-height: 3.2rem;
}

h1,
h2,
h3,
h4 {
  font-family: @font-primary;
  color: @color-dark;
  font-weight: 700;
}

h1,
h2,
h3,
h4,
.vrtx-frontpage-box.vrtx-more-false > h2,
#comments-header-left,
#main *:not(.vrtx-messages) > .vrtx-resource {
  a,
  a.vrtx-title {
    font-family: @font-primary;
    font-weight: 500;
  }
}

h1 {
  .font-size(40);
}

h2 {
  .font-size(26);
}

.vrtx-frontpage-box .item-title,
h3 {
  .font-size(20);
}

h4 {
  .font-size(16);
}

hr {
  border-bottom: 4px solid @color-secondary;
  padding-bottom: 10px;
}

#main .vrtx-back a::before {
  background: rgba(0, 0, 0, 0)
    url("../images/icon-chevron-back.svg") no-repeat
    scroll 0 2px;
}

#main
  .button:not(.comment-delete-button):not(#vrtx-comments-delete-all):not(.red):not(.white):not(.program-create-print):not(.program-create-send-dialog):not(.vrtx-program-read-more):not(.vrtx-program-how-search),
#main
  button:not(.comment-delete-button):not(#vrtx-commments-delete-all):not(.red):not(.white),
input[type="button"]:not(.red),
input[type="submit"]:not(.submit-comment-button):not(.red),
input[type="cancel"]:not(.red),
.header-search-expand,
button {
  font-family: @font-primary;
  color: @color-light;
  background-color: @color-secondary;
  .transition(all, 0.3s, ease-in);
  &:focus,
  &:hover {
    background-color: @color-primary--light;
    color: @color-light;
  }
  a:focus,
  a:hover {
    text-decoration: underline;
  }
}

.not-for-ansatte {
  .header-search-collapsable .header-search-expand {
    background-color: @color-dark;
    color: @color-light;
  }
  #head-wrapper {
    background-color: @color-primary;
    .header-search {
      top: 18px;
      z-index: 10;
      input[type="text"] {
        background: @color-primary--thinair;
        .placeholderColor(@color-neutral--dark, @color-neutral--dark);
        color: @color-dark;
      }
      .header-search-expand {
        background: url(../uio1/profile/images/responsive/search-icon.svg)
          9px 9px no-repeat;
        background-size: 20px auto;
        &:focus,
        &:hover {
          background: url(../uio1/profile/images/responsive/search-icon.svg)
            9px 9px no-repeat;
          background-size: 20px auto;
        }
      }
      button,
      .header-search-expand {
        background-color: @color-secondary;
        color: @color-dark;
        &:focus,
        &:hover {
          background-color: @color-primary--light;
          color: @color-dark;
        }
      }
    }

    #head {
      height: 165px;
      .head-menu {
        a {
          color: @color-light;
        }
        .vrtx-dropdown-wrapper-inner li a {
          color: @color-dark;
        }
      }

      #header {
        height: 100%;
        width: 100%;
        a {
          width: 300px;
          padding-top: 15px;
          position: absolute;
          img {
            height: 77px;
            margin-left: 1px;
            &.print {
              display: none;
            }
          }
          &:hover,
          &:focus {
            color: @color-secondary;
          }
        }
        span.tagline {
          top: 100px;
          position: absolute;
          left: 0px;
          .font-size(21);
          color: @color-light;
        }
      }
    }
    .vrtx-frontpage-box-picture img {
      margin-top: -36px;
    }
    .uio-app-name {
      display: none;
    }
    .head-menu {
      right: 120px;

      .language {
        display: none !important;
      }
      .head-menu-custom a,
      .not-for-ansatte .head-menu > .vrtx-login-manage-component > a,
      .not-for-ansatte .head-menu > ul > li a {
        color: @color-light;
      }
    }
  }
}
body.globalnav-smaller #globalnav ul li a {
  padding: 0;
}

#globalnav {
  background: @color-light none repeat scroll 0 0;
  ul {
    display: flex;
    justify-content: flex-start;
    background-color: @color-light;
    li.intranet {
      display: none;
    }
    li {
      margin: 10px 40px 10px 0;
      a {
        border-bottom: 3px solid @color-primary;
        padding-bottom: 15px;
        padding: 0;
        .font-size(18);
        font-weight: 500;
        position: relative;
        color: @color-primary;
        &:after {
          content: "";
          position: absolute;
          bottom: -3px;
          left: 0;
          width: 100%;
          height: 3px;
          background: @color-secondary;
          .transform(scale3d(0, 1, 1));
          .transition(all, 0.1s);
        }
        &:focus,
        &:hover {
          background: none;
          &:after {
            .transform(scale3d(1, 1, 1));
            -webkit-transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
            transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
            -webkit-transition-duration: 0.3s;
            transition-duration: 0.3s;
          }
        }
      }
      &.vrtx-active-item a {
        background: none;
        border-bottom: 3px solid @color-secondary;
        padding-bottom: 15px;
        &:focus,
        &:hover {
          background: none;
        }
        &:after {
          .transform(scale3d(1, 1, 1));
        }
      }
    }
  }
}
#left-main {
  .vrtx-breadcrumb-menu {
    li > span.vrtx-marked,
    li > a.vrtx-marked,
    li.vrtx-child > a.vrtx-marked,
    li.vrtx-parent > a.vrtx-marked {
      background: @color-light;
      color: @color-primary;
    }
    li > span.vrtx-marked,
    li > a.vrtx-marked,
    li.vrtx-child > a.vrtx-marked {
      border-top: 2px solid @color-primary--light;
      border-bottom: 2px solid @color-primary--light;
    }
    li.vrtx-child > a.vrtx-after-marked {
      border-top: none;
    }
    li > span,
    li > a {
      background: @color-light;
      font-family: @font-primary;
    }
    li.vrtx-parent {
      a {
        background-color: @color-light;
        color: @color-dark;
      }
    }
    li.vrtx-child {
      a {
        border-top: 2px solid transparent;
        padding-left: 30px;
        color: @color-primary;
      }
    }
    li.vrtx-child > a:before,
    li.vrtx-child > span:before {
      content: "\003E";
      .font-size(18);
      color: @color-primary--light;
      font-family: @font-symbol;
    }
  }
}

#main .vrtx-back a:before {
  background: url(../images/icon-chevron-back.svg)
    no-repeat 0 3px transparent;
  background-size: auto 80%;
}

a.vrtx-more::before,
div.vrtx-more a::before,
a.all-messages::before,
a.all-comments::before,
.footer > .vrtx-search-scope a::before,
a.more-url::before {
  background: url("../images/icon-chevron-right.svg")
    no-repeat 0 0px;
  background-size: 25px 25px;
  height: 1.3em;
  top: 7px;
}

.ui-accordion.ui-widget {
  .ui-accordion-header .ui-icon {
    background: transparent
      url("../images/icon-chevron-right.svg") no-repeat
      center left;
    background-size: 25px 25px;
    height: 25px;
  }
  .ui-accordion-header.ui-accordion-header-active .ui-icon {
    background-image: url("../images/icon-chevron-down.svg");
    background-size: 25px 25px;
  }
}

#main
  .button:not(.study-select):not(.edit-link):not(.program-create-print):not(.program-create-send-dialog):not(.white) {
  background-image: url(../images/arrow-forward-hover.svg);
  background-position: 95% 50%;
  background-size: auto 0.8em;
  padding-right: 80px;
  &:hover,
  &:focus {
    background-image: url(../images/arrow-forward-hover.svg);
  }
}

table.vrtx-person-listing {
  td.vrtx-person-listing-name {
    .vrtx-image {
      img {
        border-radius: 50%;
        width: 120px;
        padding: 0;
      }
      padding-right: 14px;
    }
  }
}

.grid-container {
  .vrtx-frontpage-box {
    .row-color-blue {
      background-color: @color-primary;
    }
    &.img-special-left,
    &.img-special-right,
    &.napi-decoration {
      h2 {
        .font-size(32);
        font-weight: 300;
        line-height: 1.38;
      }
      .vrtx-box-content {
        display: flex;
        justify-content: center;
        align-items: center;
        background: url(../images/napi-line.svg)
          no-repeat 0 0;
        background-size: contain;
        padding: 10%;
        p {
          .font-size(24);
          font-weight: 300;
          line-height: 1.6;
          padding-left: 80px;
        }
      }
      .vrtx-frontpage-box-picture {
        &.img-special-left {
          margin-left: -95px;
          max-width: 62%;
        }
        &.img-special-right {
          margin-right: -95px;
          max-width: 62%;
        }
      }
    }
    &.napi-decoration {
      .vrtx-box-content {
        padding: 10%;
        margin-top: 25%;
      }
    }
  }
  &.grid-color-blue,
  &.grid-color-light-blue,
  &.grid-color-green {
    + .row-all-colored {
      margin-top: -40px;
    }
  }
  &.grid-color-blue {
    background-color: @color-primary !important;
    h2,
    a,
    p {
      color: @color-light !important;
    }
    a {
      color: @color-link--light !important;
      //text-decoration: underline;
    }
  }
  &.grid-color-light-blue {
    background-color: @color-primary--light !important;
    h2,
    a,
    p {
      color: @color-dark !important;
    }
  }
  &.grid-color-red {
    background-color: @color-secondary !important;
    h2,
    a,
    p {
      color: @color-light !important;
    }
  }
}

.padding-top-bottom {
  padding: 40px 0;
}

.svg-map {
  svg#norge-map {
    width: 100%;
  }
  img {
    padding: 60px 60px 60px 0;
    height: 385px;
    width: 468px;
  }
}

#vrtx-person-contact-info-wrapper {
  .vrtx-person-image {
    border-radius: 50%;
    width: 120px;
    padding: 0;
  }
}
.round-image {
  img {
    border-radius: 50%;
    max-width: 300px;
    padding: 0;
  }
}
table {
  th {
    font-weight: 700;
    color: @color-dark;
  }
}

.vrtx-message-line-publish-date,
.lastModified,
div.time-and-place-container,
.vrtx-featured-item .vrtx-date,
*:not(.vrtx-date-info) > .published-date,
.vrtx-event-component-misc,
.vrtx-comment .comment-date {
  font-family: @font-secondary;
}

ul.social-follow {
  margin-left: -14px !important;
  > li {
    &::before {
      content: "";
    }
  }
}

.social-components {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  .twitter-x {
    background: transparent url('../uio1/images/social-list/black-svg/twitter-x.svg') no-repeat scroll
      left center;
    height: 45px;
    width: 45px;
    color: transparent !important;
    background-size: 100% auto;
    margin-left: 20px;
    &:hover,
    &:focus {
      opacity: 0.6;
      .transition(all, 0.5s, ease-in);
    }
  }
  
  a {
    color: transparent;
    padding-left: 10px;
    &:hover,
    &:focus {
      background-color: transparent;
      box-shadow: none;
      color: transparent;
    }
  }
}

#vrtx-additional-content
  .vrtx-search-main-links-box
  ul
  li#vrtx-search-main-link-altinnhold
  span {
  background: rgba(0, 0, 0, 0) none repeat scroll 0 0;
  padding-left: 14px;
}

.vrtx-tags,
.vrtx-authors,
.vrtx-date-info,
#main .vrtx-paging-feed-wrapper,
#main #vrtx-daily-events .vrtx-daily-events-listing,
#main .vrtx-resources .vrtx-daily-events-listing,
#main #vrtx-daily-events .vrtx-resource,
#main .vrtx-resources .vrtx-resource,
#vrtx-person-contact-info-extras,
#vrtx-event-listing #main .vrtx-resources > h2:first-child {
  border-color: @color-secondary;
  border-width: 4px;
}

#main .vrtx-frontpage-box.top-line {
  border-top: 4px solid @color-secondary;
  padding-top: 30px;
  margin-bottom: 30px;
}

table.vertical tbody th[scope="row"] {
  border-width: 2px;
  width: 1%;
  white-space: nowrap;
}
table td,
table th {
  border-color: @color-primary;
  border-width: 2px;
}

span.vrtx-tag-cloud-title {
  display: none;
}

blockquote {
  border-color: @color-secondary;
  p {
    font-family: @font-primary;
  }
}

.flex {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: top;
  flex-wrap: wrap;
  width: 100%;
  figure {
    margin: 20px 20px !important;
  }
}

body .vrtx-facts-container {
  background: @color-secondary--light;
  &.primary {
    background: @color-primary;
  }
}

#main
  .row-thirds-double:not(.row-one-colored):not(.row-thirds-double-no-vertical-line)
  .vrtx-frontpage-box.third-box-right {
  border-left: none;
}
#main
  .row-thirds-double:not(.row-one-colored):not(.row-thirds-double-no-vertical-line)
  .vrtx-frontpage-box.third-box-left {
  border-right: none;
}
.vrtx-frontpage-box.big-image {
  img {
    width: 100% !important;
  }
  > h2 {
    margin-left: 0px !important;
    margin-top: 125px;
  }
  .vrtx-box-content {
    margin-left: 0px !important;
  }
  .vrtx-frontpage-box-picture {
    padding-top: 40px;
  }
}
.vrtx-frontpage-box-picture.big-image.half-box-left {
  img {
    padding-right: 20px;
  }
}

.vrtx-paging-feed-wrapper a {
  font-family: @font-primary;
}

#vrtx-searchview {
  #vrtx-content,
  #right-main {
    width: 100%;
  }
  #vrtx-main-content {
    width: 738px;
  }
  #left-main {
    display: none !important;
  }
  .button.submit-button-link {
    font-family: @font-primary;
  }
}

#bottomnav {
  background-color: @color-light !important;
  height: 190px;
  padding: 0;
  border-bottom: 1px solid @color-neutral--light;
  border-top: 1px solid @color-neutral--light;
  .vrtx-subfolder-menu,
  #breadcrumb-container {
    display: none;
  }
}

body#error #footer-wrapper .logo-line {
  display: none;
}
/* Footer */
#footer-wrapper {
  color: @color-light;
  background: @color-primary !important;
  .logo-line {
    position: absolute;
    margin-top: -200px;
    width: 970px;
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    justify-content: center;
    a {
      display: inline-block;
      margin-right: 30px;
      margin-bottom: 20px;
    }
  }
  h2,
  .menu-label {
    color: @color-light;
    font-weight: 700;
  }

  #footers {
    .font-size(14) !important;
    a {
      color: @color-light;
    }
    .col-3-5 {
      width: 70%;
      .social-components {
        float: left;
        a {
          display: block;
          margin-bottom: 10px;
          margin-left: 0;
        }
      }
      .col-1-3 {
        width: 45%;
      }
      .col-1-3:nth-child(3n + 1) {
        clear: none;
      }
    }
    .col-2-5.responsible-login {
      width: 30%;
      .content {
        margin-left: 50px;
      }
    }
  }
}
#footer-wrapper
  #footers
  .logo-line
  a:not(.vrtx-dropdown-form-link):not(.vrtx-dropdown-link):hover {
  background: @color-light;
}
#footer-wrapper.red {
  background: @color-primary none;
}

@media print {
  .not-for-ansatte #head-wrapper {
    #head #header a {
      img {
        display: none;
        &.print {
          display: block;
        }
      }
      span.tagline {
        color: @color-dark;
      }
    }
    height: 150px;
  }
  .grid-container.grid-color-blue {
    .vrtx-box-content,
    h2 {
      padding-left: 20px;
      padding-right: 20px;
    }
    .napi-decoration .vrtx-box-content {
      padding: 10% 0 10% 20%;
      background-position: 10% 0;
      margin-top: 35%;
    }
    .svg-map {
      .vrtx-box-content {
        padding: 0% 10% 0% 0%;
      }
    }
  }
}

@media only screen and (max-width: 16cm) and (orientation: portrait),
  only screen and (max-width: 19cm) and (orientation: landscape) {
  .not-for-ansatte {
    #nav-offcanvas #lang-link {
      display: none;
    }
    #head-wrapper #head {
      #header {
        display: flex;
        justify-content: center;
        a {
          left: initial;
          img {
            margin-left: 50px;
          }
        }
        span.tagline {
          .font-size(18);
          color: @color-dark;
          line-height: normal;
        }
      }
    }

    #menu-wrapper {
      .menu li.active,
      .menu {
        background: @color-primary;
      }
      .menu li {
        a.toggle-navigation,
        a.toggle-search {
          color: @color-light;
        }
      }
    }
    .menu-search button:after {
      left: -22px;
    }
    .svg-map img {
      padding: 15px;
    }

    .grid-container {
      .vrtx-frontpage-box.napi-decoration {
        .vrtx-box-content {
          padding: 10%;
          margin-top: 5%;
          p {
            .font-size(18);
            padding-left: 50px;
          }
        }
      }
    }
    #main #total-main .vrtx-feed.with-images ul li a.vrtx-image:first-child {
      width: 100%;
      text-align: center;
      padding-top: 20px;
      border-top: 4px solid @color-primary--air;
    }
    #bottomnav {
      height: 500px;
    }
    #footer-wrapper {
      .logo-line {
        margin-top: -475px;
        width: 100%;
      }
      #footers {
        .uio-logo {
          width: 100%;
          float: none;
          padding-left: 0;
        }
        .col-2-5,
        .col-3-5 {
          width: 100%;
        }
        .content {
          margin-left: 10px;
        }
        .col-3-5 .col-1-3:last-child > *,
        .info-link-wrapper > * {
          margin-left: 10px;
        }
      }
    }
  }
  #vrtx-frontpage #main {
    .vrtx-frontpage-box:not(.grey-box):not(.grey-clip-box):not(.grey-box-light):not(.red):not(.responsive-hide)
      + .vrtx-frontpage-box:not(.grey-box):not(.grey-clip-box):not(.grey-box-light):not(.red).svg-map {
      border-color: @color-secondary--light;
    }
  }
}

/*------------------------------------*\
# colour
\*------------------------------------*/

// Primary, secondary etc...
@color-primary: #34365c; // blue
@color-primary--light: #8892c9; // purple blue
@color-primary--air: lighten(spin(@color-primary--light, -5), 20%);
@color-primary--thinair: lighten(spin(@color-primary--light, -5), 30%);
@color-secondary: #ed6e6c; // salmon red
@color-secondary--dark: darken(spin(@color-secondary, 5), 20%);
@color-secondary--light: #fce4e0; //light peachy pink

@color-link: @color-primary;
@color-link--light: #9cafd3; //light blue for text on dark blue

// Neutral colors
@color-neutral--dark: #4f4f4f;
@color-neutral: #949494;
@color-neutral--light: #a6c2c9;
//@color-blue-air: #e5f0f0;
@color-neutral--air: #f4f4f4; //lys gra

// Suplementary colors
@color-light: #ffffff;
@color-dark: #1d1d1b;
@color-black: #000000;
